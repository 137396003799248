<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Liste des commandes qui ont déjà un retour</h5>
        <DataTable
          :value="orders"
          v-model:expandedRows="expandedRows"
          dataKey="id"
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
          responsiveLayout="scroll"
          :loading="loading1"
          :filters="filters1"
          :paginator="true"
          class="p-datatable-gridlines"
          :rows="10"
          :rowHover="true"
          :globalFilterFields="[
            'client_id',
            'total',
            'prix_reste',
            'is_regler',
            'modeDePaiement',
            'date_expidition',
            'id',
          ]"
        >
          <template #header> </template>
          <template #empty> Aucun commande trouvé. </template>
          <template #loading>
            Chargement des données commandes. Veuillez patienter.
          </template>
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="id" header="#ID" sortable>
            <template #body="slotProps">
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="rsocial" header="Client" sortable>
            <template #body="slotProps">
              {{ slotProps.data.rsocial }}
            </template>
          </Column>

          <Column field="total" header="Total" sortable>
            <template #body="slotProps">
              {{ slotProps.data.total }}
            </template>
          </Column>
          <Column field="prix_reste" header="Le reste" sortable>
            <template #body="slotProps">
              {{ slotProps.data.prix_reste }}
            </template>
          </Column>
          <Column field="is_regler" header="Statuts de paiement" sortable>
            <template #body="slotProps">
              <div
                v-if="slotProps.data.is_regler == 1"
                class="success"
                style="color: green"
              >
                reglé
              </div>
              <div v-else class="danger s">En dette</div>
            </template>
          </Column>
          <Column
            field="modeDePaiement"
            header="Mode de paiement"
            sortable
          ></Column>

          <Column
            field="date_expidition"
            header="date d'expedition"
            sortable
          ></Column>

          <template #expansion="slotProps">
            <div class="orders-subtable">
              <h5>Produit de la commande № {{ slotProps.data.id }}</h5>
              <DataTable
                :value="slotProps.data.commandeLigne"
                responsiveLayout="scroll"
              >
                <Column field="nomProduit" header="Produit" sortable></Column>
                <Column
                  field="numSerie"
                  header="Numéro de série"
                  sortable
                ></Column>
                <Column field="note" header="Motif" sortable></Column>
                <Column field="qtt" header="quantité" sortable></Column>
                <Column field="prix" header="Prix" sortable>
                  <template #body="slotProps" sortable>
                    {{ parseFloat(slotProps.data.prix) }}
                  </template>
                </Column>
                <Column field="remise" header="Remise" sortable>
                  <template #body="slotProps" sortable>
                    {{ slotProps.data.remise }}
                  </template>
                </Column>
                 <Column
            field="confirmed"
            header="Confirmation"
            style="min-width: 12rem"
            :sortable="true"
          >
            <template #body="slotProps">
           
              <Button
                v-if="slotProps.data.confirmed == 1"
                @click="changestatuts(slotProps.data.id,slotProps.data.commande_id, 0)"
                icon="pi pi-check"
                class="p-button-raised p-button-success"
              />
              <Button
                v-else
                @click="changestatuts(slotProps.data.id,slotProps.data.commande_id, 1)"
                icon="pi pi-times"
                class="p-button-raised p-button-danger"
              />
            </template>
          </Column>
                <Column header="Image">
                  <template #body="slotProps">
                    <img
                      :src="
                        url +
                        slotProps.data.imageProduit
                      "
                      class="shadow-2"
                      width="100"
                    />
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      url:null,
      filters1: null,
      loading1: true,
      Permissions: { update: false, delete: false, add: false, show: false },
      orders: null,
      expandedRows: [],
    };
  },
  productService: null,
  created() {},
  mounted() {
    this.url = this.$Gurl;
    // this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
    // this.Permissions.update =
    //   localStorage.permissionNames.includes("Modifier produit");
    // this.Permissions.delete =
    //   localStorage.permissionNames.includes("Supprimer produit");
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter retour");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste retour");
    if (this.Permissions.show) {
      this.showAllCmd();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
     changestatuts(id,commande_id, status) {
      this.$swal({
        title: "Voulez-vous vraiment changer le statuts?",
        showDenyButton: true,
        confirmButtonText: "Changer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("confimerRetour/" + id, { confirmed: status })
            .then((res) => {
              console.log(res);
              const index = this.orders.findIndex((object) => {
                return object.id === commande_id;
              });
              const index2 = this.orders[index].commandeLigne.findIndex((object) => {
                return object.id === id;
              });


              this.orders[index].commandeLigne[index2].confirmed = status;
              this.$swal({
                icon: "success",
                title: "La réception  est bien actualisé",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    showAllCmd() {
      axios
        .get("ListeRetourClientRachid")
        .then((response) => {
          this.orders = response.data.commandes;
          this.loading1 = false;
          console.log(response.data.commandes);
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Product Expanded",
        detail: event.data.name,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Product Collapsed",
        detail: event.data.name,
        life: 3000,
      });
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      this.$toast.add({
        severity: "success",
        summary: "All Rows Expanded",
        life: 3000,
      });
    },
    collapseAll() {
      this.expandedRows = null;
      this.$toast.add({
        severity: "success",
        summary: "All Rows Collapsed",
        life: 3000,
      });
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>